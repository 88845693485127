<template>
    <form-modal
        ref="formModal"
        :title="getI18nModified({ prefix: 'COMMON.filter', modifier: 2 })"
        size="lg"
        @handleHide="handleHide">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nDate"
                            class="required label-pdv"
                            label-for="filter-date"
                        >
                            <date-range-picker
                                :value.sync="filterModal.date"
                                :max-date="new Date()"
                                :disable-clean-button="true"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nBusinessUnit"
                            class="label-pdv"
                            label-for="filter-business-unit"
                        >
                            <multiSelectWithService
                                :id="'filter-business-unit'"
                                ref="businessUnitMultiselect"
                                v-model="filterModal.business_units"
                                :service="'business-units'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nRegional"
                            label-for="filter-regional"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-regional'"
                                ref="regionalMultiselect"
                                v-model="filterModal.regionals"
                                :service="'pos-regionals'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nChain"
                            label-for="filter-chain"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-chain'"
                                ref="chainMultiselect"
                                v-model="filterModal.chains"
                                :service="'pos-chains'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nFlag"
                            label-for="filter-flag"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-flag'"
                                ref="flagMultiselect"
                                v-model="filterModal.flags"
                                :service="'pos-flags'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nChannel"
                            label-for="filter-channel"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-channel'"
                                ref="channelMultiselect"
                                v-model="filterModal.channels"
                                :service="'pos-channels'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nState"
                            label-for="filter-state"
                            class="label-pdv">
                            <multiSelect
                                :id="'filter-state'"
                                ref="stateMultiselect"
                                v-model="filterModal.states"
                                label="label"
                                track-by="value"
                                :options="states"
                                :searchable="true"
                                :multiple="true"
                                :disabled="submitLoading"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nCategory"
                            label-for="filter-category"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-category'"
                                ref="categoryMultiselect"
                                v-model="filterModal.categories"
                                :service="'pos-categories'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nUser"
                            label-for="filter-user"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-user'"
                                ref="userMultiselect"
                                v-model="filterModal.users"
                                :service="'users'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="usersParameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nPos"
                            label-for="filter-pos"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-pos'"
                                ref="posMultiselect"
                                v-model="filterModal.point_of_sales"
                                :service="'pos'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nQuestionnaire"
                            label-for="filter-questionnaire"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-questionnaire'"
                                ref="questionnaireMultiselect"
                                v-model="filterModal.questionnaires"
                                :service="'questionnaires'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="questionnaireParameters"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading"
                :title="getI18n('ACTION.filter')"
                @onClick="handleOk"
            />
            <clear-filter-button
                @onClick="handleClearFilter"
            />
        </template>
    </form-modal>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import SaveButton from '@/src/components/SaveButton'
import ClearFilterButton from '@/src/components/ClearFilterButton'
import cloneObject from '@utils/clone-object'
import DateRangePicker from '@/src/components/DateRangePicker'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'
import multiSelect from '@src/components/multiSelect.vue'
import moment from 'moment';

const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        SaveButton,
        ClearFilterButton,
        DateRangePicker,
        multiSelectWithService,
        multiSelect,
    },
    mixins: [
        validationMixin,
        swalFeedback,
    ],
    props: {
        handleSubmit: {
            type: Function,
            required: true,
            default: function () {},
        },
    },
    data() {
        return {
            i18nCommon: i18nCommon,
            filterModal: {},
            submitLoading: false,
            parameters: {
                with_admin: true,
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
            questionnaireParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                only_file: true
            },
            states: [
                { value: 'AC', label: 'Acre' },
                { value: 'AL', label: 'Alagoas' },
                { value: 'AP', label: 'Amapá' },
                { value: 'AM', label: 'Amazonas' },
                { value: 'BA', label: 'Bahia' },
                { value: 'CE', label: 'Ceará' },
                { value: 'DF', label: 'Distrito Federal' },
                { value: 'ES', label: 'Espírito Santo' },
                { value: 'GO', label: 'Goías' },
                { value: 'MA', label: 'Maranhão' },
                { value: 'MT', label: 'Mato Grosso' },
                { value: 'MS', label: 'Mato Grosso do Sul' },
                { value: 'MG', label: 'Minas Gerais' },
                { value: 'PA', label: 'Pará' },
                { value: 'PB', label: 'Paraíba' },
                { value: 'PR', label: 'Paraná' },
                { value: 'PE', label: 'Pernambuco' },
                { value: 'PI', label: 'Piauí' },
                { value: 'RJ', label: 'Rio de Janeiro' },
                { value: 'RN', label: 'Rio Grande do Norte' },
                { value: 'RS', label: 'Rio Grande do Sul' },
                { value: 'RO', label: 'Rondônia' },
                { value: 'RR', label: 'Roraíma' },
                { value: 'SC', label: 'Santa Catarina' },
                { value: 'SP', label: 'São Paulo' },
                { value: 'SE', label: 'Sergipe' },
                { value: 'TO', label: 'Tocantins' },
            ],
        }
    },
    validations: {},
    computed: {
        i18nDate() {
            return this.getI18n(i18nCommon, 'date')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },
        i18nRegional() {
            return this.getI18n('POS', 'TITLES.regional')
        },
        i18nChain() {
            return this.getI18n('POS', 'TITLES.chain')
        },
        i18nFlag() {
            return this.getI18n('POS', 'TITLES.flag')
        },
        i18nChannel() {
            return this.getI18n('POS', 'TITLES.channel')
        },
        i18nState() {
            return this.getI18n('POS', 'TITLES.state')
        },
        i18nCategory() {
            return this.getI18n('POS', 'TITLES.category')
        },
        i18nUser() {
            return this.getI18n('USERS', 'TITLES.user')
        },
        i18nPos() {
            return this.getI18n('POS', 'TITLES.pos')
        },
        i18nQuestionnaire() {
            return this.getI18n('QUESTIONNAIRES', 'TITLES.questionnaire')
        },
    },
    methods: {
        showModal(previousFilters) {
            this.filterModal = cloneObject(previousFilters);
            this.submitLoading = false
            this.$refs.formModal.show()
        },
        cleanModal() {
            this.filterModal = Object.assign(this.filterModal, {
                date: {
                    start: moment().startOf('day').add(-7, 'days').toDate(),
                    end: moment().startOf('day').toDate()
                },
                business_units: [],
                regionals: [],
                chains: [],
                flags: [],
                channels: [],
                states: [],
                categories: [],
                point_of_sales: [],
                users: [],
                questionnaires: [],
            })
        },
        handleHide() {
            if (!this.submitLoading) {
                this.cleanModal();
            }
        },
        handleOk() {
            if (this.submitLoading) {
                return;
            }

            this.submitLoading = true;
            this.$props.handleSubmit(this.filterModal);
            this.$refs.formModal.hide()
        },
        async handleClearFilter() {
            this.cleanModal()
        }
    },
}
</script>
